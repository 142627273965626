.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5rem;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5rem;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
