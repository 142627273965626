.site-header {
  position: relative;
  background-color: $topbar-background;
  color: $white;

  .top-bar {
    max-width: $global-width;
    margin: 0 auto;
  }

  .site-branding {
    display: inline-block;
    float: left;
    margin-right: rem-calc(10);
    padding: rem-calc(6 0);
  }

  .logo {
    width: 16px;
    margin-right: rem-calc(10);
  }

  .menu {
    & > li {
      & > a {
        font-size: rem-calc(14);
        font-weight: 600;
        color: rgba($white, 0.75);

        &:hover,
        &:focus {
          color: $white;
        }
      }
    }
  }
}
