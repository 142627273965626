.side-nav {
  .menu {
    margin-bottom: rem-calc(60);

    & > li {
      & > a {
        border-left: 2px solid transparent;
        font-size: rem-calc(18);
        font-weight: 600;

        &:hover,
        &:focus,
        &.is-active {
          border-left: 2px solid $primary-color;
        }

        @include breakpoint(medium up) {
          padding: rem-calc(8 16);
          font-size: rem-calc(13);
        }
      }

      &.back-to-top {
        margin-top: rem-calc(16);

        & > a {
          font-size: rem-calc(12);
          font-weight: $global-weight-normal;

          &.is-active {
            border-left: 2px solid transparent;
          }
        }
      }
    }
  }
}
